@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700');
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary {display: block;}
audio,canvas,video {display: inline-block;}
audio:not([controls]) {display: none; height: 0;}
[hidden] {display: none;}
html {font-family: sans-serif; /* 1 */-ms-text-size-adjust: 100%; /* 2 */ -webkit-text-size-adjust: 100%; /* 2 */}
a:focus {outline: none !important;}
a:active,a:hover {outline: 0;}
h1 {font-size: 2em;margin: 0.67em 0;}
abbr[title] {border-bottom: 1px dotted;}
b,strong {font-weight: bold;}
dfn {font-style: italic;}
hr {-moz-box-sizing: content-box;box-sizing: content-box;height: 0;}
mark {background: #ff0;color: #000;}
code,kbd,pre,samp {font-family: monospace, serif;font-size: 1em;}
pre {white-space: pre-wrap;}
q {quotes: "\201C" "\201D" "\2018" "\2019";}
small {font-size: 80%;}
sub,sup {font-size: 75%;line-height: 0;position: relative; vertical-align: baseline;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}
img {border:0; max-width:100%;}
a{text-decoration:none !important;}
svg:not(:root) {overflow: hidden;}
figure {margin: 0;}
fieldset {border: 1px solid #c0c0c0;margin: 0 2px;padding: 0.35em 0.625em 0.75em;}
legend {border: 0; /* 1 */ padding: 0; /* 2 */}
button,input,select,textarea {font-family: inherit; /* 1 */font-size: 100%; /* 2 */margin: 0; /* 3 */}
button,input {line-height: normal;}
button,select {text-transform: none;}
a{text-decoration:none;}
button,html input[type="button"], /*1*/input[type="reset"],input[type="submit"] {-webkit-appearance: button; /* 2 */ cursor: pointer; /* 3 */}
button[disabled],html input[disabled] {cursor: default;}
input[type="checkbox"],input[type="radio"] {box-sizing: border-box; /* 1 */ padding: 0; /* 2 */}
input[type="search"] {-webkit-appearance: textfield; /* 1 */-moz-box-sizing: content-box;-webkit-box-sizing: content-box; /* 2 */ box-sizing: content-box;}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
button::-moz-focus-inner,
input::-moz-focus-inner {border: 0;padding: 0;}
textarea {overflow: auto; /* 1 */ vertical-align: top; /* 2 */}
table {border-collapse: collapse; border-spacing: 0;}
body{
  font-family: $bodyFontFamily;
  margin: 0;
  padding: 0;
  color: $bodyTextColor;
  background: #fff;
}
.mainMenu{
  background:#e9e9e9;
}
.headtop{
  margin:0;
  background:#fff;
  padding: 10px 0;
  border-top: #fe7c00 4px solid;
  p{
    margin:0;
    padding:3px 0 0 0;
  }
  a{
    color: #056095;
  }
}
.boxtopm{
  margin:auto;
  text-align:center;
  padding: 25px 0;
  .boxtop{
    margin:auto;
    padding: 20px 0;
    text-align: center;
    p{
      color: #056095;
    }
 }
}

.welcomeG{
  margin:0;
  background:#065786 url(../images/bg-welcome.png) no-repeat fixed;
  padding: 25px 0 45px 0;
  color: #fff;
  h1{
     font-family:$headingFont;
     font-size:46px;
     color: #ff8d00;
  }
}
.Shareholders{
  padding: 45px 0;
  h2{
    padding-bottom: 20px;
    font-family:$headingFont;
  }
  a{
    color: #056095;
    &:hover{
      color:#FE7C00
    }
  }
}
.profBx{
  margin:0;
  padding: 35px 0;
  .profBx0{
    position:relative;
    h2{
      margin:auto;
      color: #000;
      font-size:24px;
      // font-family:$headingFont;
    }
    h5{
      margin:auto;
      color: #056095;
      font-size:18px;
      padding-bottom:15px;
      padding-top:5px;
    }
  }
  .profBx1{
    margin-top:130px;
    text-align:center;
    background:#fff;
    box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.2);
    padding: 120px 15px 0px 15px;
    &:hover{
      box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.4);
    }
  }
  .profimg{
    margin:auto;
    text-align:center;
    width: 170px;
    height:170px;
    border-radius: 50%;
    overflow:hidden;
    position:absolute;
    top:-85px;
    left: 0;
    right: 0;
    img{
      height:190px;
      max-width: auto !important;
      width:auto;

    }
  }
}
.socialbx{
  background:#5e5e5e;
  padding: 15px 0;
  margin:auto;
  text-align:center;
  margin-top:25px;
  a{
    margin:0;
    display:inline-block;
    padding:0 5px;
  }
}
.footermenu{
  margin:auto;
  text-align:center;
  background:#414141;
  padding: 25px 0;
  ul{
    margin:0;
    padding: 0;
    li{
      display:inline-block;
      a{
        color: #b8b8b8;
        display:inline-block;
        padding: 0 25px;
        &:hover{
          color: #fff;
        }
      }
    }
  }
}
.footerbx{
  background:#333333;
  text-align:center;
  padding: 45px 0;
}
.footerb{
  padding: 12px 0;
  .footerr{
    text-align:right;
  }
}

#pageb{
  .contab{
    min-height: 250px;
    p{
      text-align:justify;
    }
  }
  .welcomeG{
    padding: 10px 0;
    margin-bottom: 25px;
    h1{
      text-align:center;
    }
  }
}











// banner
.jssorl-009-spin img {
            animation-name: jssorl-009-spin;
            animation-duration: 1.6s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        @keyframes jssorl-009-spin {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }


        .jssorb101 .i {position:absolute;cursor:pointer;}
        .jssorb101 .i .ci {fill:#000;}
        .jssorb101 .i .co {fill:#fff;opacity:.3;}
        .jssorb101 .i:hover .co {fill:#ff9933;opacity: 1;}
        .jssorb101 .i:hover .ci {fill:#fff;}
        .jssorb101 .iav .ci {fill:#fff;stroke-width:0;}
        .jssorb101 .iav .co {fill:#46d1d3;opacity: 1;}
        .jssorb101 .i.idn {opacity:.3;}

        .jssora051 {display:block;position:absolute;cursor:pointer;}
        .jssora051 .a {fill:none;stroke:#fff;stroke-width:360;stroke-miterlimit:10;}
        .jssora051:hover {opacity:.8;}
        .jssora051.jssora051dn {opacity:.5;}
        .jssora051.jssora051ds {opacity:.3;pointer-events:none;}
        // banner end

