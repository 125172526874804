
$primaryColor: #000;
$secondryColor: #939393;
$menuBg: linear-gradient(to right, #05ABE0 0%,#764EFF 100%); 
$btnBg: linear-gradient(to right,  #d64c1a 0%,#f2771f 100%); 
$btnHov: linear-gradient(to right,  #f2771f 0%,#d64c1a 100%); 
$btnBlack: linear-gradient(to right,  #000 0%,#3b3b3b 100%); 
$btnHovblack: linear-gradient(to right,  #3b3b3b 0%,#000 100%); 
$redBg:  #910005;
$bodyTextColor: #6e6e6e;
$black: #000;
$white: #fff;
$placeholderColor: #000;
$red: #ff0000;
$active: #28a7e1;
$hover: #0e6eb8;
$bodyFontFamily: 'Montserrat', sans-serif;
$headingFont: 'Aladin', cursive;
$secondFontFamily: 'Montserrat', sans-serif;
$h1Size: 36px;
$h2Size: 24px;
$h3Size: 22px;
$h4Size: 18px;
